import {EventCallback} from './event'
import {UnbluApiError, UnbluErrorType} from '../unblu-api-error'
import {
    CATEGORY_ACTION_NOT_GRANTED,
    CATEGORY_EXECUTION_EXCEPTION,
    CATEGORY_INVALID_FUNCTION_ARGUMENTS,
    CATEGORY_INVALID_FUNCTION_CALL,
    CATEGORY_TIMEOUT
} from './java-error-codes'


const SUPPORTED_MAJOR_VERSION = 2

interface ApiConsumer {
    onUnbluEvent(event: Event): Promise<void>
}

interface CollabServerInternalApi {
    getApiVersion(): string

    execute(moduleName: string, functionName: string, args: any[]): Promise<any>

    registerEventListener(moduleName: string, eventName: string, listener: EventCallback): Promise<void>

    removeEventListener(moduleName: string, eventName: string, listener: EventCallback): Promise<void>
}

export class ApiBridge {
    private internal: CollabServerInternalApi
    private readonly eventHandler: ApiConsumer

    /**
     * instantiates the bridge that links the UNBLU internal API provided by the collaboration server with the UNBLU JS-API
     * @param mountPoint the global unblu object under which the internal API is registered.
     */
    constructor(private mountPoint: any, private mountName: string) {
    }

    public async waitUntilLoaded(timeout: number, promise?: Promise<void>): Promise<void> {
        const timeoutTimestamp = Date.now() + timeout
        return new Promise<void>((resolve, reject) => {
            const waitForLoaded = () => {
                if (timeoutTimestamp - Date.now() > 0) {
                    if (this.checkLoaded()) return resolve()
                    setTimeout(waitForLoaded, 50)
                } else {
                    reject(new UnbluApiError(UnbluErrorType.INITIALIZATION_TIMEOUT, 'Timeout while waiting for collaboration server API to be loaded.'))
                }
            }
            waitForLoaded()
        })
    }

    private checkLoaded(): boolean {
        const error = this.mountPoint['loadError']
        if (error) {
            throw new UnbluApiError(error.type, error.detail)
        }
        this.internal = this.mountPoint[this.mountName]
        return this.internal != null
    }


    public checkCompatibility(): void {
        if (!this.internal) {
            throw new UnbluApiError(UnbluErrorType.INCOMPATIBLE_UNBLU_VERSION, 'Incompatible unblu collaboration server, no API bridge provided.')
        } else if (typeof this.internal.getApiVersion !== 'function') {
            throw new UnbluApiError(UnbluErrorType.INCOMPATIBLE_UNBLU_VERSION, 'Incompatible unblu collaboration server, incompatible API bridge.')
        }
        const version = this.internal.getApiVersion()
        if (+version[0] != SUPPORTED_MAJOR_VERSION) {
            throw new UnbluApiError(UnbluErrorType.INCOMPATIBLE_UNBLU_VERSION, `Incompatible collaboration server version. 
                Supported API version: ${SUPPORTED_MAJOR_VERSION}.x.x.
                collaboration server API version: ${version}.`)
        }
    }


    /**
     * Calls an API function of the internal unblu collaboration server API.
     * @param moduleName The module to which the function belongs.
     * @param functionName The function to call.
     * @param args The arguments to pass to the function.
     */
    public async callApiFunction(moduleName: string, functionName: string, args: any[]): Promise<any> {
        try {
            return await this.internal.execute(moduleName, functionName, args)
        } catch (e) {
            switch (e.type) {
                case CATEGORY_INVALID_FUNCTION_CALL:
                    throw new UnbluApiError(UnbluErrorType.INVALID_FUNCTION_CALL, e.message)
                case CATEGORY_INVALID_FUNCTION_ARGUMENTS:
                    throw new UnbluApiError(UnbluErrorType.INVALID_FUNCTION_ARGUMENTS, e.message)
                case CATEGORY_ACTION_NOT_GRANTED:
                    throw new UnbluApiError(UnbluErrorType.ACTION_NOT_GRANTED, e.message)
                case CATEGORY_EXECUTION_EXCEPTION:
                    throw new UnbluApiError(UnbluErrorType.EXECUTION_EXCEPTION, e.message)
                case CATEGORY_TIMEOUT:
                    throw new UnbluApiError(UnbluErrorType.TIMEOUT, e.message)
                default:
                    throw new UnbluApiError(UnbluErrorType.EXECUTION_EXCEPTION, '' + e)
            }
        }
    }

    /**
     * Registers a callback for an event emitted by the internal unblu collaboration server API.
     * @param module The module that emits the event.
     * @param event The event name.
     * @param callback The callback which will be called every time the event is emitted.
     */
    public on(module: string, event: string, callback: EventCallback): Promise<void> {
        return this.internal.registerEventListener(module, event, callback)
    }

    /**
     * Unregisters a callback for an event emitted by the internal unblu collaboration server API.
     * @param module The module that emits the event.
     * @param event The event name.
     * @param callback Optionally callback which will be removed, if none is provided all listeners of the event will be removed.
     */
    public off(module: string, event: string, callback?: EventCallback): Promise<void> {
        return this.internal.removeEventListener(module, event, callback)
    }

}