import {InternalModule} from './module'
import {ApiBridge} from '../api-bridge'
import {AgentAvailabilityState} from '../../model/agent-availability-state'


export type AgentAvailabilityEventType =
    'availableChange'
    | 'availabilityChange'

const enum AgentAvailabilityFunction {
    isAgentAvailable = 'isAgentAvailable',
    getAgentAvailabilityState = 'getAgentAvailabilityState'
}

export class AgentAvailabilityModule extends InternalModule<AgentAvailabilityFunction, AgentAvailabilityEventType> {
    constructor(bridge: ApiBridge) {
        super(bridge, 'agentavailability')
    }

    public isAgentAvailable(): Promise<boolean> {
        return this.callApiFunction(AgentAvailabilityFunction.isAgentAvailable, [])
    }

    public getAgentAvailabilityState(): Promise<AgentAvailabilityState> {
        return this.callApiFunction(AgentAvailabilityFunction.getAgentAvailabilityState, [])
    }
}