export enum ConversationType {
    /**
     * (Visitor initiated, visitor centered) A visitor creates an incoming chat conversation.
     */
    CHAT_REQUEST = 'CHAT_REQUEST',
    /**
     * (Visitor initiated, visitor centered) A visitor creates an incoming chat conversation while all agents are offline.
     */
    OFFLINE_CHAT_REQUEST = 'OFFLINE_CHAT_REQUEST',
    /**
     * (Visitor initiated, visitor centered) A visitor creates an incoming video conversation.
     */
    VIDEO_REQUEST = 'VIDEO_REQUEST',
    /**
     * (Visitor initiated, visitor centered) A visitor creates an incoming audio conversation.
     */
    AUDIO_REQUEST = 'AUDIO_REQUEST',
    /**
     * (Visitor initiated, visitor centered) A visitor creates an incoming universal co-browsing conversation.
     */
    HEADLESS_BROWSER_REQUEST = 'HEADLESS_BROWSER_REQUEST',
    /**
     * (Visitor initiated, visitor centered) A visitor creates an incoming embedded co-browsing conversation.
     */
    DOMCAP_BROWSER_REQUEST = 'DOMCAP_BROWSER_REQUEST',
    /**
     * (Visitor initiated, visitor centered) A visitor creates an incoming mobile co-browsing conversation.
     */
    MOBILE_COBROWSING_REQUEST = 'MOBILE_COBROWSING_REQUEST',
    /**
     * (Visitor initiated, visitor centered) A visitor creates an incoming whiteboard conversation.
     */
    WHITEBOARD_REQUEST = 'WHITEBOARD_REQUEST',
    /**
     * (Visitor initiated, visitor centered) A visitor creates an incoming screen sharing conversation.
     */
    SCREEN_SHARING_REQUEST = 'SCREEN_SHARING_REQUEST',
    /**
     * (Api initiated, visitor centered) A visitor creates a conversation via API which is not added to the queue and where he can invite other visitor to do
     * embedded
     */
    VISITOR_COBROWSING = 'VISITOR_COBROWSING',
}