import {BaseGeneralEventType, BaseGeneralModule} from "../../shared/internal/module/base-general-module"
import {ViewMode} from "../../view-mode"


export type GeneralEventType =
    BaseGeneralEventType |
    'fullscreenChange' |
    'viewModeChange'

const enum GeneralFunction {
    isFullscreen = 'isFullscreen',
    setFullscreen = 'setFullscreen',
    getViewMode = 'getViewMode',
    setViewMode = 'setViewMode'
}


export class GeneralModule extends BaseGeneralModule<GeneralFunction, GeneralEventType> {

    public isFullscreen(): Promise<boolean> {
        return this.callApiFunction(GeneralFunction.isFullscreen, [])
    }

    public setFullscreen(enable: boolean): Promise<void> {
        return this.callApiFunction(GeneralFunction.setFullscreen, [enable])
    }

    public getViewMode(): Promise<ViewMode> {
        return this.callApiFunction(GeneralFunction.getViewMode, [])
    }

    public setViewMode(mode: ViewMode) {
        return this.callApiFunction(GeneralFunction.setViewMode, [mode])
    }
}