export type Listener = (data?: any) => void;

export class EventEmitter {
    private listeners: { [key: string]: EventListener[] } = {};

    /**
     * Resets the emitter by removing all registered listeners.
     */
    public reset(): void {
        this.listeners = {};
    }

    /**
     * Adds an event listeners
     * @param event the event to listen to
     * @param listener the listener to be called.
     */
    public on(event: string, listener: Listener) {
        this.listeners[event] = this.listeners[event] || [];
        if (this.listeners[event].indexOf(listener) === -1)
            this.listeners[event].push(listener);
    }

    /**
     * removes a previously registered listener
     * @param event the event
     * @param listener the listener that should be removed.
     * @return `true` if the listener was removed, `false` otherwise.
     */
    off(event: string, listener: any): boolean {
        const listeners = this.listeners[event] || [];
        const index = listeners.indexOf(listener);
        if (index > -1) {
            listeners.splice(index, 1);
            return true;
        }
        return false;
    }

    /**
     * removes all listeners for the given event.
     * @param event the event for which all listeners will be removed.
     */
    offAll(event: string) {
        delete this.listeners[event];
    }

    /**
     * Checks weather at least one listener exists for a given event.
     * @param event the event to check for
     * @return weather or not any listeners for the given event are registered.
     */
    hasListeners(event: string): boolean {
        return this.listeners[event] != null && this.listeners[event].length > 0;
    }

    /**
     * Returns all events that have at least one listeners registered to them.
     * @return An array containing all events that have at least one listener.
     * If no listeners are registered at all, an empty array will be returned.
     */
    getEventsWithListeners(): string[] {
        const events: string[] = [];
        for (let event in this.listeners) {
            if (this.listeners[event].length)
                events.push(event);
        }
        return events;
    }

    /**
     * Emits an event dispatching it to all listeners registered for it.
     * @param event the event name.
     * @param data the event data.
     */
    emit(event: string, data?: any) {
        const listeners = this.listeners[event];
        if (listeners) {
            //iterate over a copy of the array as otherwise if listeners unregister themself,
            //it will change the array and iteration will skip them
            [...listeners].forEach(l => {
                try {
                    l(data);
                } catch (e) {
                    console.warn('Error dispatching event:', event, 'in listener:', l, e);
                }
            });
        }
    }

}