import {InternalModule} from '../../shared/internal/module/module'
import {ApiBridge} from '../../shared/internal/api-bridge'
import {UnbluEmbeddedAppElement} from "../../unblu-embedded-app-element"

export type EmbeddedMetaEventType =
    'deinitializing' |
    'deinitialized'


const enum EmbeddedMetaFunction {
    setUnbluEmbeddedAppElement = 'setUnbluEmbeddedAppElement',
    waitUntilInitialized = 'waitUntilInitialized',
    deinitialize = 'deinitialize'
}

export class EmbeddedMetaModule extends InternalModule<EmbeddedMetaFunction, EmbeddedMetaEventType> {
    constructor(bridge: ApiBridge) {
        super(bridge, 'embeddedmeta')
    }

    public setUnbluEmbeddedAppElement(appElement: UnbluEmbeddedAppElement): Promise<void> {
        return this.callApiFunction(EmbeddedMetaFunction.setUnbluEmbeddedAppElement, [appElement])
    }

    public waitUntilInitialized(): Promise<void> {
        return this.callApiFunction(EmbeddedMetaFunction.waitUntilInitialized, [])
    }

    public deinitialize(): Promise<boolean> {
        return this.callApiFunction(EmbeddedMetaFunction.deinitialize, [])
    }
}